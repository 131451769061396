/* General styles */
#header {
  width: 100%;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  padding: 20px 0;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  font-size: 1.5em;
  font-weight: medium;
  color: #fff;
  text-decoration: none;
    /* Add this line to remove underline */
  }
  
  /* You might also want to add a hover effect */
  .logo:hover {
    text-decoration: none;
    /* Ensure underline doesn't appear on hover */
  }

.nav-container {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 30px;
}

.nav-links li {
  position: relative;
}

.nav-links li a {
  color: #fff;
  text-decoration: none;
  font-size: 1.2em;
  padding: 5px 10px;
  border-radius: 8px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.nav-links li a::after {
  content: '';
  width: 0;
  height: 3px;
  background: #ff004f;
  position: absolute;
  left: 0;
  bottom: -6px;
  transition: width 0.3s ease;
}

.nav-links li a:hover::after {
  width: 100%;
}

.theme-switch {
  cursor: pointer;
  display: none;
  /* Hide theme switch */
}

.nav-toggle {
  display: none;
  /* Hide the toggle by default */
}

/* Mobile-specific styles */
@media (max-width: 768px) {
  .logo {
    font-size: 1.2em;
    text-align: left;
  }

  .nav-container {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .nav-links {
    flex-direction: column;
    align-items: flex-start;
    display: none;
    /* Hide the nav links by default */
    background-color: #333;
    width: 100%;
    position: absolute;
    top: 60px;
    left: 0;
    z-index: 100;
    padding: 10px 0;
  }

  .nav-links.active {
    display: flex;
    /* Show the nav links when active */
  }

  .nav-links li {
    margin: 10px 0;
  }

  .nav-links li a {
    font-size: 1.2em;
    width: 100%;
    text-align: center;
  }

  .nav-toggle {
    display: block;
    cursor: pointer;
    font-size: 1.5em;
    margin-right: 10px;
  }
}