.contact {
  text-align: center;
  padding: 50px 20px;
  background-color: #1a1a1a;
  /* Match the background color */
  color: white;
  margin-top: 50px;
  /* Add some margin at the top to separate from the Resume section */
}

.contact h2 {
  font-size: 3rem;
  margin-bottom: 20px;
}

.contact p {
  font-size: 1.2rem;
  margin-bottom: 30px;
  color: rgb(141, 141, 141);
}

.contact-icons {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.contact-icons a img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  /* Make the icons circular */
  background-color: white;
  padding: 1px;
  transition: transform 0.3s;
  object-fit: cover;
  /* Ensure the image covers the container */
}

.contact-icons a img:hover {
  transform: scale(1.1);
}