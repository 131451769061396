@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-500px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(500px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.about h2,
.about-svg,
.about-content,
.about-content h3,
.skills-list {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 2s ease, transform 2s ease;
}

.about .animate {
  opacity: 1;
  transform: translateY(0);
}

.about h2.animate {
  animation: fadeInUp 2s forwards;
}

.about-svg.animate {
  animation: fadeInLeft 2.5s forwards;
}

.about-content.animate {
  animation: fadeInRight 2.5s forwards;
}

.about-content h3.animate {
  animation: fadeInRight 1.5s forwards;
}

.skills-list.animate {
  animation: fadeInRight 2.5s forwards;
}

.about {
  margin: 4rem auto 0;
  padding: 20px 0;
}

.about h2 {
  text-align: center;
  font-size: 60px;
  margin-bottom: 30px;
  color: white;
  font-weight: 450;
}

.about-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  gap: 40px;
}

.about-svg {
  flex: 1;
  max-width: 45%;
}

.about-svg img {
  height: auto;
  margin-top: 100px;
  max-width: 100%;
}

.about-content {
  flex: 1;
  margin-left: 20px;
  color: white;
  text-align: left;
}

.about-content h3 {
  font-size: 40px;
  font-weight: 400;
  padding-top: 60px;
  padding-bottom: 10px;
  text-align: left;
  padding-left: 40px;
}

.skills-list {
  list-style-type: none;
  color: rgb(141, 141, 141);
  font-size: 18px;
  padding-top: 20px;
  padding-left: 0;
}

.skills-list li {
  font-size: 1.2em;
  margin-bottom: 10px;
  display: flex;
  align-items: flex-start;
}

.skills-list li::before {
  content: "•";
  color: rgb(227, 64, 95);
  font-size: 2em;
  display: inline-block;
  margin-right: 0.5em;
  line-height: 0;
  margin-top: 0.27em;
}

@media (max-width: 768px) {
  .about-container {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 0 20px;
  }

  .about h2 {
    font-size: 2.5em;
  }

  .about-svg {
    max-width: 80%;
    margin-bottom: 20px;
  }

  .about-content {
    margin-left: 0;
    padding: 0 20px;
  }

  .about-content h3 {
    font-size: 2em;
    padding-top: 20px;
  }

  .skills-list {
    font-size: 1em;
    padding-top: 10px;
  }

  .skills-list li {
    font-size: 1em;
  }

  .skills-list li::before {
    font-size: 1.5em;
    margin-top: 0.2em;
  }
}

@media (max-width: 480px) {
  .about h2 {
    font-size: 2em;
  }

  .about-svg {
    max-width: 90%;
    margin-bottom: 20px;
  }

  .about-content h3 {
    font-size: 1.5em;
  }

  .skills-list {
    font-size: 0.9em;
  }

  .skills-list li {
    font-size: 0.9em;
  }

  .skills-list li::before {
    font-size: 1.2em;
  }
}