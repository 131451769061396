* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Define the SF Pro font */
@font-face {
  font-family: 'SF Pro';
  src: url('/public/fonts/San Francisco Pro Display/SF-Pro-Display-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro';
  src: url('/public/fonts/San Francisco Pro Display/SF-Pro-Display-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: 'SF Pro', Arial, sans-serif;
  background-color: #1a1a1a;
  color: #fff;
}

.container {
  padding: 10px 10%;
  /* Correct padding definition for consistent left and right padding */
}


.hero {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  /* Reduced top padding to bring the hero section higher */
  height: calc(100vh - 220px);
  /* Adjusted height to ensure it fits better within the viewport */
}


/* -----------about--------------- */
#about {
  padding: 120px 0;
  color: #ababab;
}

.row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.about-col-1 {
  flex-basis: 35%;
}

.about-col-1 img {
  width: 100%;
  border-radius: 15px;
}

.about-col-2 {
  flex-basis: 60%;
}

.sub-title {
  font-size: 60px;
  color: #fff;
  font-weight: regular;
}

.tab-titles {
  display: flex;
  margin: 20px 0 40px;
}

.tab-links {
  margin-right: 50px;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  position: relative;
}

.tab-links::after {
  content: '';
  width: 0;
  height: 3px;
  background: #ff004f;
  position: absolute;
  left: 0;
  bottom: -8px;
  transition: 0.5s;
}

.tab-links.active-link::after {
  width: 50%;
}

.tab-contents ul li {
  list-style: none;
  margin: 10px 0;
}

.tab-contents ul li span {
  color: #b54769;
  font-size: 14px;
}

.tab-contents {
  display: none;
}

.tab-contents.active-tab {
  display: block;
}
/* animation for body */
@keyframes bodyUpDown {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(10px);
  }
}
/* eyes animation */
@keyframes eyesLeftRight {

  0%,
  100% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(3px);
  }
}

#bodyman {
  animation: bodyUpDown 5.5s infinite;
}
#eyes {
  animation: eyesLeftRight 5s infinite;
  transform-origin: center;
}