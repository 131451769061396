@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-500px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(500px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.education h2,
.education-svg,
.education-content,
.education-content h3,
.degrees {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 2s ease, transform 2s ease;
}

.education .animate {
  opacity: 1;
  transform: translateY(0);
}

.education h2.animate {
  animation: fadeInUp 2s forwards;
}

.education-svg.animate {
  animation: fadeInRight 2.5s forwards;
}

.education-content.animate {
  animation: fadeInLeft 2.5s forwards;
}

.education-content h3.animate {
  animation: fadeInLeft 1.5s forwards;
}

.degrees.animate {
  animation: fadeInLeft 2.5s forwards;
}

.education {
  margin: 4rem auto 0;
  padding: 20px 0;
}

.education h2 {
  text-align: center;
  font-size: 60px;
  margin-bottom: 30px;
  color: white;
  font-weight: 450;
}

.education-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  gap: 40px;
}

.education-svg {
  flex: 1;
  max-width: 45%;
}

.education-svg img {
  height: auto;
  margin-top: 100px;
  max-width: 100%;
}

.education-content {
  flex: 1;
  margin-left: 20px;
  color: white;
  text-align: left;
}

.education-content h3 {
  font-size: 40px;
  font-weight: 400;
  padding-top: 60px;
  padding-bottom: 10px;
  text-align: left;
}

.degrees {
  list-style-type: none;
  color: rgb(141, 141, 141);
  font-size: 16px;
  padding-top: 20px;
  padding-left: 0;
}

.degree {
  background: #1e1e1e;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  /* Adjusted alignment */
  position: relative;
}

.degree-content {
  flex: 1;
}

.degree h4 {
  margin: 0;
  font-size: 1.5em;
  color: white;
}

.degree p {
  margin: 5px 0;
  color: white;
}

.degree ul {
  list-style-type: disc;
  margin: 10px 0 0 20px;
  color: #bbbbbb;
}

.degree ul li {
  margin-bottom: 5px;
}

.degree span {
  display: block;
  margin-top: 10px;
  font-size: 0.9em;
  color: #bbbbbb;
}

.degree-icon {
  width: 50px;
  height: 50px;
  position: absolute;
  /* Positioned absolutely */
  top: 20px;
  /* Adjusted top position */
  right: 20px;
  /* Adjusted right position */
}

.degree-icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@media (max-width: 768px) {
  .education-container {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .education-svg {
    max-width: 80%;
    margin-bottom: 20px;
  }

  .education-content {
    margin-left: 0;
  }

  .degree {
    flex-direction: column;
    align-items: flex-start;
  }

  .degree-icon {
    position: relative;
    /* Reset positioning for mobile view */
    top: auto;
    right: auto;
    margin-top: 10px;
  }
}