@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.hero {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
  min-height: calc(100vh - 20px);
  overflow: visible;
  opacity: 0;
  animation: fadeInUp 1s forwards;
}

.hero-content {
  flex: 1;
  margin-bottom: 30px;
  text-align: left;
  margin-left: 30px;
  max-width: 50%;
  /* Ensure content takes up 50% width */
}

.hero-content h1 {
  font-size: 4em;
  margin-bottom: 20px;
  color: #fff;
}

.typed-container {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.typed-text {
  font-size: 30px;
  line-height: 1.4;
  color: rgb(141, 141, 141);
  font-weight: medium;
}

.highlight {
  color: rgb(227, 64, 95);
}

.wave {
  display: inline-block;
  animation: wave 2s ease-in-out 2;
  animation-fill-mode: forwards;
}

.wave:hover {
  animation: wave 2s ease-in-out infinite;
  animation-fill-mode: forwards;
}

.contact-button {
  display: inline-block;
  padding: 15px 30px;
  background-color: #fc0f56;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  margin-top: 25px;
  font-size: 1.2em;
}

.contact-button:hover {
  background-color: #d81b60;
}

.hero-image {
  flex: 1;
  max-width: 50%;
  /* Ensure image takes up 50% width */
  height: auto;
  position: relative;
  overflow: visible;
  padding-bottom: 50px;
  margin-bottom: -20px;
  display: flex;
  justify-content: center;
}

.hero-image svg {
  width: 100%;
  height: auto;
  overflow: visible;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .hero {
    flex-direction: column;
    /* Change direction to column on smaller screens */
  }

  .hero-content {
    max-width: 100%;
    text-align: center;
    margin-left: 0;
  }

  .hero-content h1 {
    font-size: 3.5em;
  }

  .typed-text {
    font-size: 1.5em;
  }

  .contact-button {
    font-size: 1.2em;
    padding: 12px 24px;
  }

  .hero-image {
    max-width: 80%;
    margin-top: 20px;
  }

  .hero-image svg {
    width: 100%;
    height: auto;
  }
}

@media (max-width: 768px) {
  .hero {
    flex-direction: column;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
  }

  .hero-content {
    max-width: 100%;
    text-align: center;
    margin-left: 0;
  }

  .hero-content h1 {
    font-size: 3em;
  }

  .typed-text {
    font-size: 1.3em;
  }

  .contact-button {
    font-size: 1em;
    padding: 10px 20px;
  }

  .hero-image {
    margin-top: 10px;
    max-width: 100%;
    padding-left: 0;
    order: 2;
  }

  .hero-image svg {
    width: 100%;
    height: auto;
  }
}

@media (max-width: 480px) {
  .hero-content h1 {
    font-size: 2.5em;
  }

  .typed-text {
    font-size: 18px;
    font-weight: medium;
  }

  .contact-button {
    font-size: 1.2em;
    padding: 15px 55px;
    margin-bottom: 0;
  }

  .hero-image {
    margin-top: 10px;
    max-width: 100%;
    margin-left: 0;
    padding-left: 0;
    order: 2;
  }

  .hero-image svg {
    width: 100%;
    height: auto;
  }
}