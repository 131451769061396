.resume {
  margin: 0 140px;
  padding: 20px;
  max-width: 1200px;
  background: #1e1e1e;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.resume h2 {
  text-align: center;
  font-size: 60px;
  margin-bottom: 30px;
  color: white;
  font-weight: 450;
}

.resume-section {
  margin-bottom: 40px;
  text-align: left;
}

.resume-section h3 {
  font-size: 30px;
  font-weight: 400;
  margin-bottom: 20px;
  color: white;
}

.resume-section p,
.resume-section li {
  color: rgb(141, 141, 141);
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 10px;
}

.resume-section ul {
  padding-left: 20px;
  list-style: none;
}

.resume-section ul li {
  position: relative;
  padding-left: 20px;
}

.resume-section ul li::before {
  content: "•";
  color: rgb(227, 64, 95);
  font-size: 1.5em;
  position: absolute;
  left: 0;
  top: 0;
  line-height: 1;
}